.App .top-bar {
  background: #fff;
  padding-right: 20px;
  padding-bottom: 12px;
}
.App .top-bar .container {
  align-items: center;
}
.App .top-bar .account {
  padding: 0px 10px;
  cursor: pointer;
}
.App .top-bar .account .avator {
  vertical-align: middle;
  margin-right: 10px;
}
.App .side-nav .logo {
  transition: all 0.3s;
  overflow: hidden;
  margin: 10px 0px 20px 0px;
  text-align: center;
  white-space: nowrap;
}
.App .side-nav .logo img {
  width: 50px;
  padding-right: 5px;
}
.App .side-nav .logo h2 {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 300;
  margin: 2px;
  overflow: hidden;
}
.App .side-nav ul.ant-menu,
.App .side-nav .ant-layout-sider-trigger {
  background-color: #2d373c;
}
.App .side-nav ul.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  box-shadow: inherit;
}
.App .side-nav ul li.ant-menu-item-selected {
  background-color: #1f282d;
}
.App .side-nav.ant-layout-sider-collapsed .logo {
  padding-left: 4px;
}
.App .side-nav.ant-layout-sider-collapsed h2 {
  max-width: 0;
  display: inline-block;
  opacity: 0;
}
.App .login-bg {
  height: 100vh;
  background-image: url("/cover.jpg") !important;
  background-size: cover;
}
.App .login-bg .login-box {
  background-color: #fff;
  border: solid 1px #ccc;
  border-radius: 4px;
  padding: 40px;
  padding-bottom: 20px;
  position: absolute;
  right: 100px;
  bottom: 120px;
}
.App .login-bg .login-box .login-logo {
  text-align: center;
  margin-bottom: 20px;
}
.App .login-bg .login-box .login-logo img {
  width: 100px;
  height: 100px;
}
.App .login-bg .login-box h2 {
  margin-bottom: 30px;
}
.App .login-bg .login-box button {
  height: 40px;
  width: 100%;
  background: #fb7100;
}
.App .login-bg .login-box .login-error {
  height: 22px;
  color: red;
}
.App .ant-layout-content {
  background-color: #f5f8fa;
  padding: 8px;
}
.App .pagingation {
  float: right;
}
.App .pagingation button {
  border-color: transparent;
  background-color: transparent;
  margin: 16px;
}
.App .ant-card {
  border-radius: 5px;
  padding: 5px;
}
.App .ant-card .ant-card-head {
  margin: 0px -5px;
  padding-left: 29px;
}
.App .ant-card.detail-view {
  color: #222;
  margin-bottom: 20px;
}
.App .ant-card.detail-view .ant-card-body {
  line-height: 2;
}
.App span.item-label {
  color: #777;
}
.App div.photo-grid {
  display: inline-block;
}
.App div.photo-grid img {
  border-radius: 5px;
  margin: 5px 5px 5px 0px;
}
.App .text-editor {
  border: 1px solid #F1F1F1;
  padding: 5px;
  min-height: 200px;
}
.ant-layout .ant-layout-header {
  background: #fff;
  padding: 10px;
  height: inherit;
  line-height: inherit;
  border-bottom: 1px solid #e8e8e8;
}
.ant-layout .ant-layout-footer {
  text-align: center;
  background: #fff;
  padding: 10px 50px;
  border-top: 1px solid #e8e8e8;
}
div.img-select {
  text-align: center;
  padding-bottom: 5px;
  width: 150px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}
div.img-select:hover {
  cursor: pointer;
}
div.img-select img {
  margin-bottom: 4px;
}
.ant-modal-body form {
  margin-top: 24px;
}
.fa {
  margin-right: 8px;
}
.code-box {
  /*border: 1px solid #e9e9e9;
  border-radius: 4px;*/
  display: inline-block;
  width: 100%;
  position: relative;
  margin: 0 0 16px;
  transition: all 0.2s;
}
.code-box section {
  position: relative;
  border-bottom: 1px solid #e9e9e9;
  padding: 10px 10px 10px 10px;
}
.code-box section.last {
  border-bottom: 0px;
}
.code-box-title {
  position: absolute;
  top: -14px;
  padding: 1px 8px;
  margin-left: -8px;
  border-radius: 4px;
  background: #fff;
  transition: background-color 0.4s;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-weight: 700;
}
section.action-panel {
  padding: 10px;
  border: none;
  border-top: 1px solid #e9e9e9;
}
section.action-panel button {
  min-width: 120px;
  min-height: 30px;
  margin-right: 20px;
}
section.navigation-panel {
  padding: 0px;
  border: none;
}
section.navigation-panel button {
  min-width: 120px;
  min-height: 30px;
  margin-left: 10px;
}
.add-or-select {
  display: inline-block;
  width: 100%;
}
.add-or-select .ant-select {
  max-width: 300px;
  margin-right: 4px;
}
div.add-or-select > div.parent-display > span {
  font-size: 10px;
  margin: 0px;
}
div.add-or-select > div.parent-display {
  margin-top: -30px;
  padding: 0px;
  height: 25px;
  color: blue;
}
.ant-form-table {
  margin-bottom: 24px;
}
.ant-table-tbody > tr > td {
  vertical-align: top;
}
.ant-table-row .ant-form-item {
  margin-bottom: inherit;
}
.ant-table-wrapper tr.ant-table-row td {
  padding: 4px;
  word-break: break-word;
}
.ant-table-wrapper thead.ant-table-thead th {
  font-weight: bold;
  padding: 6px 4px;
  word-break: break-word;
}
.ant-table-footer button.ant-btn {
  margin-top: inherit;
}
.ant-table-footer .right {
  text-align: right;
}
td.number {
  text-align: right;
}
.columnAlignRight {
  text-align: right !important;
}
button.silence {
  border: none;
}
button.pull-right {
  float: right;
}
.ant-table-thead > tr > th:not(:first-child),
.ant-table-tbody > tr > td:not(:first-child) {
  text-align: center;
}
.summary-box {
  margin: 0;
}
.ant-form-text {
  font-weight: bold;
}
.status .ant-tag .anticon {
  margin-right: 6px;
}
#commit-hash {
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  font-size: 11px;
  padding: 2px 4px;
  border: solid 1px #ccc;
  border-radius: 4px;
  margin: 0px 6px;
}
.container {
  display: flex;
}
.left-half {
  flex: 1 1;
  padding-top: 15;
  padding-bottom: 15;
}
.right-half {
  flex: 1 1;
  float: right;
  padding-top: 15;
  padding-bottom: 15;
}
.box {
  position: relative;
}
.ribbon {
  position: absolute;
  left: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 100px;
  height: 100px;
  text-align: center;
}
.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 135px;
  display: block;
  box-shadow: 0 3px 10px -5px #000000;
  position: absolute;
  top: 31px;
  left: -27px;
}
.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #8F5408;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #8F5408;
}
.ribbon span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #8F5408;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #8F5408;
}

.example {
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 30px 50px;
    margin: 20px 0;
  }
    .example {
      background: rgba(255,255,255,0.08);
    }
.panel-box {
  margin-bottom: 20px;
  margin-left: auto;
}

.ant-layout-content .customer .card_item_one,
.ant-layout-content .customer .card_item_two,
.ant-layout-content .customer .card_item_three {
  box-shadow: 0 0 30px whitesmoke;
}
.ant-layout-content .customer .customer__footer {
  margin-top: '20px';
}

.ant-card-head {
  box-shadow: 0 10px 10px -5px #e6f7ff;
}
.card-extra-child {
  display: 'flex';
  margin: '0 auto';
  justify-content: 'space-between';
  padding: '10px 0';
  align-items: 'center';
  text-align: 'center';
  align-self: 'center';
}
.date-range-picker {
  height: "auto";
  width: "auto";
  border: "none";
  border-radius: "0px";
  border-color: 'blue';
  cursor: "pointer";
  margin: "0px";
  padding: "0px";
  margin-left: 12;
}
.history__bar {
  display: flex;
}
.history__bar .history__title {
  padding-left: 2px !important;
  color: black;
  font-size-adjust: inherit;
  font-feature-settings: inherit;
  font-variant-caps: inherit;
}
.history__bar .history__date {
  display: flex;
  flex-direction: column;
  margin-left: 35% !important;
}
.history__bar .history__filter {
  margin-left: 20px !important;
}
.history__bar .history__filter__clear {
  margin-left: 20px !important ;
}
.history__bar .history__export {
  margin-left: auto;
  color: blue;
}

.alert__info {
  margin-bottom: 5px;
  align-items: center;
  width: auto;
  background-color: white;
}

.ant-card-head {
  box-shadow: 0 10px 10px -5px #e6f7ff;
}
.card-extra-child {
  display: 'flex';
  margin: '0 auto';
  justify-content: 'space-between';
  padding: '10px 0';
  align-items: 'center';
  text-align: 'center';
  align-self: 'center';
}
.date-range-picker {
  height: "auto";
  width: "auto";
  border: "none";
  border-radius: "0px";
  border-color: 'blue';
  cursor: "pointer";
  margin: "0px";
  padding: "0px";
  margin-left: 12;
}
.member_alert_info {
  margin-bottom: 5px;
  align-items: center;
  width: auto;
  background-color: white;
}
.member__bar {
  display: flex;
}
.member__bar .member__bar__title {
  padding-left: 2px !important;
  color: black;
  font-size-adjust: inherit;
  font-feature-settings: inherit;
  font-variant-caps: inherit;
}
.member__bar .member__date {
  display: flex;
  flex-direction: column;
  margin-left: 35% !important;
}
.member__bar .member__bar__filter {
  margin-left: 20px !important;
}
.member__bar .member__filter__clear {
  margin-left: 20px !important ;
}
.member__bar .history__export {
  margin-left: auto;
  color: blue;
}
.top__member__bar {
  display: flex;
}
.top__member__bar .top__member__title {
  padding-left: 2px !important;
  color: black;
  font-size-adjust: inherit;
  font-feature-settings: inherit;
  font-variant-caps: inherit;
}
.top__member__bar .top_member__date {
  margin-left: 50% !important;
}
.top__member__bar .history__export {
  margin-left: auto;
  color: blue;
}
.new__member__bar {
  display: flex;
}
.new__member__bar .new__bar__title {
  padding-left: 2px !important;
  color: black;
  font-size-adjust: inherit;
  font-feature-settings: inherit;
  font-variant-caps: inherit;
}
.new__member__bar .new_member__date {
  margin-left: 50% !important;
}
.new__member__bar .history__export {
  margin-left: auto;
  color: blue;
}
.active__member__bar {
  display: flex;
}
.active__member__bar .active__bar__title {
  padding-left: 2px !important;
  color: black;
  font-size-adjust: inherit;
  font-feature-settings: inherit;
  font-variant-caps: inherit;
}
.active__member__bar .active_member__date {
  margin-left: 50% !important;
}
.active__member__bar .history__export {
  margin-left: auto;
  color: blue;
}
.tier__member__bar {
  display: flex;
}
.tier__member__bar .tier__member__title {
  padding-left: 2px !important;
  color: black;
  font-size-adjust: inherit;
  font-feature-settings: inherit;
  font-variant-caps: inherit;
}
.tier__member__bar .tier__filter {
  margin-left: 60% !important;
}
.tier__member__bar .tier__filter__clear {
  margin-left: 12px !important;
}
.tier__member__bar .history__export {
  margin-left: auto;
  color: blue;
}

