.ant-card-head{
    box-shadow: 0 10px 10px -5px #e6f7ff;
}
.card-extra-child{
    display: 'flex';
    margin: '0 auto';
    justify-content: 'space-between';
    padding: '10px 0';
    align-items: 'center';
    text-align: 'center';
    align-self: 'center'
}
.date-range-picker{
    height: "auto";
    width: "auto";
    border: "none";
    border-radius: "0px";
    border-color: 'blue';
    cursor: "pointer";
    margin: "0px";
    padding: "0px";
    margin-left: 12
}
.member_alert_info{
    margin-bottom: 5px;
    align-items: center;
    width: auto;
    background-color: white;
}
.member__bar {
    display: flex;
    .member__bar__title{
        padding-left: 2px !important;
        color: black;
        font-size-adjust: inherit;
        font-variant-caps: inherit;
    }
    .member__date{
        display: flex;
        flex-direction: column;
        margin-left: 35% !important;
    }
    .member__bar__filter{
        margin-left:  20px !important;
    }
    .member__filter__clear{
        margin-left: 20px !important ;
    }
    .history__export{
        margin-left:auto;
        color: blue
    }
}

.top__member__bar {
    display: flex;
.top__member__title{
        padding-left: 2px !important;
        color: black;
        font-size-adjust: inherit;
        font-variant-caps: inherit;
    }
    .top_member__date{
        margin-left: 50% !important;
    }
    .history__export{
       margin-left:auto;
        color: blue
    }
}
.new__member__bar {
    display: flex;
.new__bar__title{
        padding-left: 2px !important;
        color: black;
        font-size-adjust: inherit;
        font-variant-caps: inherit;
    }
    .new_member__date{
        margin-left: 50% !important;
    }
    .history__export{
       margin-left:auto;
        color: blue
    }
}

.active__member__bar {
    display: flex;
.active__bar__title{
        padding-left: 2px !important;
        color: black;
        font-size-adjust: inherit;
        font-variant-caps: inherit;
    }
    .active_member__date{
       // margin-left: auto;
      margin-left: 50% !important;
    }
    .history__export{
       margin-left:auto;
        color: blue
    }
}

.tier__member__bar {
    display: flex;
.tier__member__title{
        padding-left: 2px !important;
        color: black;
        font-size-adjust: inherit;
        font-variant-caps: inherit;
    }
    .tier__filter{
        margin-left: 60% !important;
    }
    .tier__filter__clear{
        margin-left: 12px !important;
    }
    .history__export{
       margin-left:auto;
        color: blue
    }
}

@primary-color: #0baf4f;@font-family: 'Open Sans', sans-serif;