.ant-layout-content{
     .customer{
        .card_item_one,.card_item_two,.card_item_three{
            box-shadow: 0 0 30px whitesmoke;
        }
        .customer__footer{
          margin-top: '20px';
        }
     }
}
@primary-color: #0baf4f;@font-family: 'Open Sans', sans-serif;