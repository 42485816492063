.ant-card-head{
    box-shadow: 0 10px 10px -5px #e6f7ff;
}
.card-extra-child{
    display: 'flex';
    margin: '0 auto';
    justify-content: 'space-between';
    padding: '10px 0';
    align-items: 'center';
    text-align: 'center';
    align-self: 'center'
}
.date-range-picker{
    height: "auto";
    width: "auto";
    border: "none";
    border-radius: "0px";
    border-color: 'blue';
    cursor: "pointer";
    margin: "0px";
    padding: "0px";
    margin-left: 12
}
.history__bar {
    display: flex;
    .history__title{
        padding-left: 2px !important;
        color: black;
        font-size-adjust: inherit;
        font-variant-caps: inherit;
    }
    .history__date{
        display: flex;
        flex-direction: column;
        margin-left: 35% !important;
    }
    .history__filter{
        margin-left:  20px !important;
    }
    .history__filter__clear{
        margin-left: 20px !important ;
    }
    .history__export{
        margin-left:auto;
        color: blue
    }
}
@primary-color: #0baf4f;@font-family: 'Open Sans', sans-serif;